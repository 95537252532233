import styled from "styled-components";

const Phone = styled.button`
  width: 100%;
  height: auto;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: transform 0.5s ease-in-out;
  -moz-transition: transform 0.5s ease-in-out;
  -o-transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  cursor: pointer;
  z-index: 100;
  background-color: transparent;
  border: none;
  outline: none;
  margin-left: 10px;
  display: flex;
  align-items: center;
  user-select: none;

  a {
    text-decoration: none;
    color: ${({ color, theme }) =>
      color === "dark" ? theme.colors.darkGrey : theme.colors.white};
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: transform 0.25s ease-in-out;
    -moz-transition: transform 0.25s ease-in-out;
    -o-transition: transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out;
  }
  .icon {
    margin-top: 3px;
    margin-right: 5px;
    color: ${({ color, theme }) =>
      color === "dark" ? theme.colors.darkGrey : theme.colors.white};
  }
  svg path {
    color: ${({ color, theme }) =>
      color === "dark" ? theme.colors.darkGrey : theme.colors.white};
  }
  .number {
    display: none;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
      display: block;
    }
  }
`;

export default {
  Phone,
};
