import React from "react";
import S from "./styles";

const Hamburger = ({ color, menuOpen, ...rest }) => {
  return (
    <S.Hamburger className={menuOpen ? "open" : ""} color={color} {...rest}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </S.Hamburger>
  );
};

export default Hamburger;
