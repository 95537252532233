import React, { useState, useContext, useEffect, useRef } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { gsap } from "gsap";
import S from "./styles";
import { createMenuStructure } from "../../../utils/helpers";
import Hamburger from "../../layout/Hamburger";
import Phone from "../../layout/Phone";
import { HeaderContext } from "../../../context/HeaderContext";
import { SiteSettingsContext } from "../../../context/SiteSettingsContext";

const MainMenu = ({ menuOpen, setMenuOpen, color }) => {
  const { theme, setTheme, pageTheme } = useContext(HeaderContext);
  const { settings } = useContext(SiteSettingsContext);
  const [scrolled, setScrolled] = useState(false);
  const overlayRef = useRef(null);

  const { data } = useStaticQuery(graphql`
    query MainMenuQuery {
      data: contentfulMenu(position: { eq: "main" }) {
        items {
          id
          title
          slug
          externalLink
          items {
            id
            title
            slug
            externalLink
            items {
              id
              title
              slug
              externalLink
            }
          }
        }
      }
    }
  `);

  if (!data || !data.items) {
    return null;
  }

  useEffect(() => {
    document.querySelector("body").classList.remove("menu-open");
  }, []);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      setScrolled(currPos.y < 0);
    },
    [scrolled]
  );

  const menuOpenAnimation = () => {
    console.log("menu open animation");
    const animation = gsap.timeline();
    animation.to(overlayRef.current, {
      x: 0,
      ease: "Quad.easeInOut",
      duration: 0.5,
    });
    animation.to(overlayRef.current.querySelectorAll("ul"), {
      opacity: 1,
    });
  };

  const menuCloseAnimation = () => {
    const animation = gsap.timeline();
    animation.to(overlayRef.current.querySelectorAll("ul"), {
      opacity: 0,
      duration: 0.2,
    });
    animation.to(overlayRef.current, {
      x: "100%",
      ease: "Quad.easeInOut",
    });
  };

  const menuItemSelectAnimation = e => {
    const animation = gsap.timeline();
    animation.to(overlayRef.current.querySelectorAll("ul"), {
      opacity: 0,
    });
    animation.to(overlayRef.current, {
      x: "100%",
    });
  };

  const handleMenu = () => {
    if (menuOpen) {
      setTheme(scrolled ? "dark" : pageTheme);
      document.querySelector("body").classList.remove("menu-open");
      menuCloseAnimation();
    } else {
      setTheme("dark");
      document.querySelector("body").classList.add("menu-open");
      menuOpenAnimation();
    }

    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <S.MainMenu className={menuOpen ? "open" : ""}>
        <Phone color={color} onClick={() => handleMenu()} />
        <Hamburger
          onClick={() => handleMenu()}
          menuOpen={menuOpen}
          color={theme}
        />
      </S.MainMenu>
      <S.Overlay
        className={menuOpen ? "open" : ""}
        onClick={() => handleMenu()}
      >
        <div
          className="inner"
          ref={overlayRef}
          onClick={e => e.stopPropagation()}
        >
          {data.items &&
            createMenuStructure(data.items, "", {
              onClick: e => {
                handleMenu();
                menuItemSelectAnimation(e);
              },
            })}
        </div>
      </S.Overlay>
    </>
  );
};

export default MainMenu;
