import styled from "styled-components";

const ContactCTA = styled.section`
  position: relative;
  padding: 240px 0 0px 0;
  background-color: ${({ theme }) => theme.colors.lightGrey2};

  &.contact-page {
    text-align: center;

    button {
      margin: 0 auto;
    }

    .call-us-text {
      margin-left: auto;
      margin-right: auto;
    }

    .content {
      max-width: 815px;
      padding-bottom: 120px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 100px 0 0 0;
  }

  .content {
    margin: 0 auto;
    padding-bottom: 120px;
  }

  .white-text {
    color: white;
    font-family: ${({ theme }) => theme.fonts.butlerMedium};
    font-size: 45px;
    line-height: 60px;
    margin-bottom: 20px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      font-size: 30px;
      line-height: 40px;
    }
  }

  .black-text {
    font-family: ${({ theme }) => theme.fonts.ttNormsMedium};
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 50px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      font-family: ${({ theme }) => theme.fonts.ttNormsRegular};
      font-size: 18px;
      line-height: 28px;
    }
  }

  .call-us-text {
    font-family: ${({ theme }) => theme.fonts.butlerMedium};
    font-size: 30px;
    line-height: 34px;
    margin-top: 50px;
    margin-bottom: 20px;
    border-bottom: 2px solid ${({ theme }) => theme.colors.darkGrey};
    max-width: 350px;
    padding-bottom: 20px;
  }

  .call-us-number {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 1.3px;

    a {
      font-family: ${({ theme }) => theme.fonts.ttNormsBold};
      text-decoration: none;
    }
  }

  button {
    padding: 20px 30px;
    border: 2px solid ${({ theme }) => theme.colors.darkGrey};
    margin: 20px 0;
    border-radius: 40px;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;

    span {
      margin-right: 10px;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      margin: 0;
    }
  }
`;

export default {
  ContactCTA,
};
