import styled from "styled-components";

const Hamburger = styled.button`
  width: 40px;
  height: 28px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: transform 0.5s ease-in-out;
  -moz-transition: transform 0.5s ease-in-out;
  -o-transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  cursor: pointer;
  z-index: 100;
  background-color: transparent;
  border: none;
  outline: none;
  margin-left: 10px;
  display: flex;
  align-items: center;
  user-select: none;

  &.open {
    span {
      &:nth-child(1) {
        top: 11px;
        width: 0%;
        left: 50%;
      }

      &:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        top: 11px;
        width: 0%;
        left: 50%;
      }
    }
  }

  span {
    display: block;
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: ${({ color, theme }) =>
      color === "dark" ? theme.colors.darkGrey : theme.colors.white};
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: transform 0.25s ease-in-out;
    -moz-transition: transform 0.25s ease-in-out;
    -o-transition: transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 11px;
    }

    &:nth-child(4) {
      top: 22px;
    }
  }
`;

export default {
  Hamburger,
};
