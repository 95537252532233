import styled from "styled-components";

const Container = styled.div`
  position: relative;
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;
  padding: 0 60px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: 0 20px;
  }
`;

export default {
  Container,
};
