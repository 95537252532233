import styled from "styled-components";

interface FormProps {
  open: boolean;
}


const Form = styled.div<FormProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  pointer-events: ${({ open }) => (open ? "all" : "none")};
  transition: background-color 0.2s ease;
  background-color: ${({ open }) =>
    open ? "rgba(0,0,0,0.5)" : "rgba(0,0,0,0)"};

  .form-wrapper {

    position: relative;
    width: 66%;
    transition: transform 0.5s ease;
    transform: ${({ open }) => (open ? "translateX(0%)" : "translateX(100%)")};
    margin-left: auto;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
      width: 80%;
        iframe{
          padding-bottom: 13vh;
        }
    }
    
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      width: 100%;
    }

    .close-button {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 9999;
      border: none;
      padding: 0;
      margin: 0;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export default {
  Form,
};
