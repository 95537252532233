import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    color: ${({ theme }) => theme.colors.darkGrey};
    font-family: ${({ theme }) => theme.fonts.ttNormsRegular};
    text-rendering: optimizeLegibility;
    font-variant-ligatures: none;
  }
  
  body {
    scroll-behavior: smooth;
    &.fixed {
      position: fixed;
    }
    &.menu-open {
      overflow: hidden;
      padding-right: 14px;

      @media screen and (max-width: 1024px) {
        padding-right: 0;
      }
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${({ theme }) => theme.fonts.butlerMedium};
    font-weight: normal;
  }
`;

export default GlobalStyle;
