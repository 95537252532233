import styled from "styled-components";

const Credits = styled.div`
  position: relative;
  padding: 25px 0;
  background-color: ${({ theme }) => theme.colors.white};

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      flex-direction: column;
    }

    .copyright,
    .developers {
      width: 33.3333%;
      color: ${({ theme }) => theme.colors.mediumGrey};
      font-size: 14px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        width: 100%;
      }

      a {
        color: ${({ theme }) => theme.colors.darkGrey};
        text-decoration: none;
      }
    }
  }
`;

export default {
  Credits,
};
