import React from "react";

interface LogoProps {
  fill: string;
  style?: React.CSSProperties;
}

const RaindrumULogo = ({ fill, style }: LogoProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="50"
      viewBox="0 0 39 50"
      style={style}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill={fill} transform="translate(-311 -753)">
          <g transform="translate(0 705)">
            <g>
              <g>
                <g transform="translate(311 41)">
                  <g transform="translate(0 7)">
                    <g>
                      <path d="M7.577 18.275v12.117c0 11.93 4.346 17.88 13.04 17.88 5.583 0 9.491-2.814 11.764-8.47.638-2.815.957-5.148.957-6.97V19.48l-.04-8.204h1.277l-.199 6.998c-.04.991-.08 3.619-.08 7.881v6.06c0 6.647-1.794 11.5-5.385 14.556-2.83 2.01-5.98 3.03-9.53 3.03h-.956c-7.38 0-11.966-3.325-13.68-10-.44-1.635-.637-3.351-.637-5.148v-16.38l-.24-6.943h3.67l.04 6.944"></path>
                      <path d="M37.96 2.744H.482C.16 2.744 0 2.56 0 2.195V.55C0 .183.16 0 .482 0H37.96c.322.06.483.244.483.549v1.646c-.054.366-.215.549-.483.549"></path>
                      <path d="M7.577 18.275v12.117c0 11.93 4.346 17.88 13.04 17.88 5.583 0 9.491-2.814 11.764-8.47.638-2.815.957-5.148.957-6.97V19.48l-.04-8.204h1.277l-.199 6.998c-.04.991-.08 3.619-.08 7.881v6.06c0 6.647-1.794 11.5-5.385 14.556-2.83 2.01-5.98 3.03-9.53 3.03h-.956c-7.38 0-11.966-3.325-13.68-10-.44-1.635-.637-3.351-.637-5.148v-16.38l-.24-6.943h3.67l.04 6.944"></path>
                      <path d="M37.96 2.744H.482C.16 2.744 0 2.56 0 2.195V.55C0 .183.16 0 .482 0H37.96c.322.06.483.244.483.549v1.646c-.054.366-.215.549-.483.549"></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default RaindrumULogo;
