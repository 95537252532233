import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Container from "../../common/Container";
import RaindrumLogo from "../../common/Logos/RaindrumLogo";
import ContactMenu from "../ContactMenu";
import SocialMenu from "../SocialMenu";
import FooterMenu from "../FooterMenu";
import NewsletterSignup from "../NewsletterSignup";
import S from "./styles";

const Footer = ({ location }) => {
  return (
    <S.Footer>
      <Container>
        <div className="content">
          <div className="left">
            <AniLink to="/" className="logo" bg="white" cover direction="left">
              <RaindrumLogo fill="dark" />
            </AniLink>
            <FooterMenu />
          </div>
          <div className="right">
            <NewsletterSignup location={location} />
            <ContactMenu />
          </div>
        </div>
      </Container>
    </S.Footer>
  );
};

export default Footer;
