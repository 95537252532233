import React, { useContext, useEffect } from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { HeaderContext } from "../../../context/HeaderContext";
import RaindrumLogo from "../../common/Logos/RaindrumLogo";

const Logo = props => {
  const { theme } = useContext(HeaderContext);

  return (
    <AniLink
      cover
      direction="right"
      bg="white"
      to="/"
      style={{ zIndex: 50 }}
      {...props}
    >
      <RaindrumLogo fill={theme} />
    </AniLink>
  );
};

export default Logo;
