import styled from "styled-components";

const InsightsBar = styled.section`
  position: relative;
  background-color: ${({ theme }) => theme.colors.darkGrey};
  padding: 40px 0;

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h4 {
    color: ${({ theme }) => theme.colors.white};
    font-weight: normal;
    font-family: ${({ theme }) => theme.fonts.butlerMedium};
    font-size: 30px;
    line-height: 58px;
    padding: 0 30px 0 36px;
  }

  p {
    font-size: 20px;
    padding-right: 20px;
    color: ${({ theme }) => theme.colors.white};
  }
`;

export default {
  InsightsBar,
};
