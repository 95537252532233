import React, { useState, useContext } from "react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { HeaderContext } from "../../../context/HeaderContext";
import Logo from "../Logo";
import MainMenu from "../MainMenu";
import S from "./styles";

const Header: React.FunctionComponent = () => {
  const [scrolled, setScrolled] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const {
    theme,
    pageTheme,
    setTheme,
    headerVisible,
    setHeaderVisible,
  } = useContext(HeaderContext);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const atTop = currPos.y == 0;
      const isScrolled = currPos.y < 0;
      const scrolledUp = currPos.y > prevPos.y;
      setScrolled(isScrolled);
      if (theme != "dark") {
        setTheme("dark");
      }

      if (isScrolled && scrolledUp && !atTop) {
        setHeaderVisible(true);
        if (theme != "dark") {
          setTheme("dark");
        }
      }

      if (atTop) {
        setTheme(menuOpen ? "dark" : pageTheme);
      }
    },
    [scrolled, menuOpen, pageTheme, theme]
  );

  return (
    <S.Header scrolled={scrolled} headerVisible={headerVisible}>
      <Logo className="logo" />
      <MainMenu
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        color={pageTheme}
      />
    </S.Header>
  );
};

export default Header;
