import React from "react";
import S from "./styles";

interface ContainerProps {
  children: React.ReactNode;
  [rest: string]: any;
}

const Container: React.FunctionComponent<ContainerProps> = ({
  children,
  ...rest
}) => {
  return (
    <S.Container className="container" {...rest}>
      {children}
    </S.Container>
  );
};

export default Container;
