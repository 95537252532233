import React from "react";
import { useTheme } from "styled-components";
import Container from "../../common/Container";
import S from "./styles";

const Credits = () => {
  const date = new Date();

  return (
    <S.Credits>
      <Container>
        <div className="content">
          <div className="copyright">
            &copy; {date.getFullYear()} Raindrum Pty Ltd. All Rights Reserved.
          </div>
        </div>
      </Container>
    </S.Credits>
  );
};

export default Credits;
