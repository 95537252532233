import styled from "styled-components";
import AniLink from "gatsby-plugin-transition-link/AniLink";

const MainMenu = styled.nav`
  position: relative;
  display: flex;
  align-items: center;

  &.open {
    padding-right: 15px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      padding-right: 0;
    }
  }
`;

const Contact = styled.div`
  .call-us-number {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 1.3px;

    a {
      font-family: ${({ theme }) => theme.fonts.ttNormsBold};
      text-decoration: none;
      color: ${({ theme }) => theme.colors.white};
    }
    .icon {
      color: white;
    }
    p {
      color: white;
    }
  }
`;

const EmailButton = styled(AniLink)`
  position: relative;
  border: none;
  background: transparent;
  outline: none;
  z-index: 20;
  cursor: pointer;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    display: none;
  }
`;

const Overlay = styled.div`
  position: fixed;
  display: flex;
  justify-content: flex-end;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  font-family: ${({ theme }) => theme.fonts.butlerMedium};
  transition: background-color 0.2s ease;
  background-color: rgba(0, 0, 0, 0);
  pointer-events: none;

  &.open {
    background-color: rgba(0, 0, 0, 0.5);
    pointer-events: all;
    cursor: pointer;
  }

  .inner {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    right: 0;
    transform: translateX(100%);
    background-color: ${({ theme }) => theme.colors.white};
    width: 66%;
    height: 100vh;
    cursor: initial;

    @media screen and (max-width: 1440px) {
      width: 80%;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
      justify-content: flex-start;
      padding-top: 120px;
      width: 100%;
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    max-width: 740px;

    ul {
      display: none;
    }

    li {
      width: 50%;

      @media screen and (max-width: ${({ theme }) =>
          theme.breakpoints.medium}) {
        width: 100%;
        text-align: center;
      }
    }

    a {
      position: relative;
      display: inline-block;
      font-family: ${({ theme }) => theme.fonts.butlerMedium};
      color: ${({ theme }) => theme.colors.darkGrey};
      text-decoration: none;
      font-size: 35px;
      line-height: 45px;
      padding: 40px 0 0 0;
      ${({ theme }) => theme.mixins.underlineLink};

      @media screen and (max-width: ${({ theme }) =>
          theme.breakpoints.medium}) {
        font-size: 30px;
        padding: 30px 0 0 0;
      }

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        font-size: 24px;
        line-height: 50px;
        padding: 0;
      }
    }
  }
`;

export default {
  MainMenu,
  Overlay,
  EmailButton,
  Contact,
};
