import React, { useState, useContext } from "react";
import { SiteSettingsContext } from "../../../context/SiteSettingsContext";
import { HeaderContext } from "../../../context/HeaderContext";
import Container from "../../common/Container";
import Form from "../Form";
import ArrowRightIcon from "../../../assets/images/arrow_right.svg";
import S from "./styles";
import Locations from "../../content/Locations";

const ContactCTA = ({ pathContext: { contentful_id = "" } = {} }) => {
  const [formOpen, setFormOpen] = useState<boolean>(false);
  const {
    settings: {
      contactTypeformUrl,
      contactNumber,
      contactPageID,
      contactText1,
      contactText2,
      address,
    },
  } = useContext(SiteSettingsContext);
  const { setHeaderVisible } = useContext(HeaderContext);

  return (
    <S.ContactCTA
      className={contactPageID === contentful_id ? "contact-page" : ""}
    >
      <Container>
        <div className="content">
          <div className="white-text">{contactText1}</div>
          <div className="black-text">{contactText2}</div>
          {contactTypeformUrl && (
            <>
              <button
                onClick={() => {
                  setFormOpen(true);
                  setHeaderVisible(false);
                }}
              >
                <span>Take the next step</span> <ArrowRightIcon />
              </button>

              <Form
                open={formOpen}
                setOpen={setFormOpen}
                formUrl={contactTypeformUrl}
              />
            </>
          )}
          {contactNumber && (
            <>
              <div className="call-us-text">Or call us</div>
              <div className="call-us-number">
                <a href={`tel:${contactNumber}`}>{contactNumber}</a>
              </div>
            </>
          )}
        </div>
      </Container>
      {/* testing if direct text works */}
      {address}
    </S.ContactCTA>
  );
};

export default ContactCTA;
