import React from "react";
import { Link } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { INLINES, BLOCKS } from "@contentful/rich-text-types";

export const createMenuStructure = (
  items = [],
  slug = "",
  props = {}
): JSX.Element => {
  return (
    <ul>
      {items.map(item => {
        const itemSlug = slug + (item.slug === "home" ? "" : `/${item.slug}`);

        if (item.externalLink) {
          return (
            <li className="item" key={item.id}>
              <a href={item.externalLink}>{item.title}</a>
            </li>
          );
        }

        return (
          <li className="item" key={item.id}>
            <AniLink
              to={itemSlug}
              cover
              direction="right"
              bg="white"
              duration={1}
              {...props}
            >
              {item.title}
            </AniLink>
            {/* {item.items &&
              item.items.length &&
              createMenuStructure(item.items, itemSlug, props)} */}
          </li>
        );
      })}
    </ul>
  );
};

export const getSlugByID = ({ pageMap, pageID }): string => {
  const page = pageMap.find(path => path.contentful_id == pageID);

  return (page && page.slug) || "/";
};

export const contentfulRichTextLinks = ({ pageMap }) => {
  return {
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      const linkPath = getSlugByID({
        pageMap,
        pageID: node.data.target.sys.contentful_id,
      });

      return (
        <AniLink bg="white" cover direction="right" to={linkPath}>
          {children}
        </AniLink>
      );
    },
  };
};

export const contentfulRichTextInlineImage = {
  [BLOCKS.EMBEDDED_ASSET]: node => {
    if(!node?.data?.target?.fields) return null;

    const { title, file } = node?.data?.target?.fields;
    const { url } = file["en-US"];

    if (!url) return null;

    return <img className="inline-image" src={url} alt={title["en-US"]} />;
  },
};

export const truncateString = (string: string, length: number): string => {
  if (typeof string !== "string") return "";

  if (string.length <= length) {
    return string;
  }

  return string.slice(0, length) + "...";
};
