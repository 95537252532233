import React from "react";

interface LogoProps {
  fill: string;
  style?: React.CSSProperties;
}

const colors = {
  light: "white",
  dark: "black",
};

const RaindrumLogo = ({ fill, ...rest }: LogoProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="244"
      height="30"
      viewBox="0 0 244 30"
      {...rest}
    >
      <defs>
        <path
          id="path-1"
          d="M2.10065698e-14 2.62582123e-15L243.618039 2.62582123e-15 243.618039 29.1370558 2.10065698e-14 29.1370558z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-69 -40)">
          <g transform="translate(69 40)">
            <g>
              <mask id="mask-2" fill="#fff">
                <use xlinkHref="#path-1"></use>
              </mask>
              <path
                fill={colors[fill]}
                d="M2.706 18.363h1.867c3.758 0 5.624-2.054 5.624-6.149 0-2.745-1.377-4.517-4.154-5.317l-1.47-.142c-1.656 0-2.496.8-2.496 2.385v8.392c.093.55.303.831.63.831zm9.778-7.137v1.412c0 1.992-1.376 3.717-4.153 5.177v.125c0 .863 1.726 3.717 5.203 8.549 1.144 1.584 2.03 2.369 2.707 2.369v.282h-1.26c-2.637 0-5.134-2.698-7.49-8.11-1.098-1.584-2.077-2.384-2.918-2.384H2.706c-.21 0-.42.141-.63.423v9.789H0V6.614h7.07c3.01 0 4.808 1.538 5.414 4.612z"
                mask="url(#mask-2)"
              ></path>
              <path
                fill={colors[fill]}
                d="M43.75 19.68c-.957-3.999-2.007-7.105-3.104-9.317h-.21c-.373 0-1.26 2.416-2.683 7.232l-.887 2.51h7.023l-.139-.424zm1.236-3.058l4.76 12.518h-2.285c-.374 0-1.401-2.73-3.081-8.204l-.14-.283h-7.304c-.63 0-1.61 2.495-2.893 7.483-.21.674-.42 1.004-.63 1.004h-.42v-.141c5.134-14.918 7.957-22.385 8.47-22.385.42 0 1.587 3.342 3.523 10.008z"
                mask="url(#mask-2)"
              ></path>
              <path
                fill={colors[fill]}
                d="M68.598 6.928v21.93c0 .188-.14.282-.42.282h-1.26c-.28 0-.42-.094-.42-.282V6.928c.046-.188.187-.282.42-.282h1.26c.28.031.42.125.42.282"
                mask="url(#mask-2)"
              ></path>
              <path
                fill={colors[fill]}
                d="M97.346 20.512c1.75 2.228 2.94 3.514 3.524 3.843V6.897c.046-.188.186-.283.42-.283h.396v22.244c0 .188-.14.282-.397.282-.396-.11-3.36-3.922-8.913-11.404-3.71-4.722-5.858-7.09-6.394-7.09v18.227c0 .173-.14.267-.42.267l-.21-.267V6.614h.63c.327.11 4.107 4.737 11.364 13.898"
                mask="url(#mask-2)"
              ></path>
              <path
                fill={colors[fill]}
                d="M123.221 28.716h3.337c1.61 0 3.57-1.302 5.834-3.905 1.376-1.648 2.076-3.938 2.076-6.871v-1.396c0-4.69-2.357-7.812-7.07-9.365-.63-.188-1.54-.282-2.707-.282h-1.47c-1.47 0-2.31.658-2.497 1.96V25.36c.164 2.243.98 3.357 2.497 3.357zm13.114-16.22c.56 1.444.84 2.934.84 4.471 0 5.632-2.637 9.412-7.91 11.326-1.167.565-3.034.847-5.624.847h-4.784c-.279 0-.42-.094-.42-.282V6.614h7.49c5.298 0 8.751 1.96 10.408 5.883z"
                mask="url(#mask-2)"
              ></path>
              <path
                fill={colors[fill]}
                d="M156.632 18.363h1.867c3.758 0 5.624-2.054 5.624-6.149 0-2.745-1.377-4.517-4.154-5.317l-1.47-.142c-1.656 0-2.497.8-2.497 2.385v8.392c.094.55.304.831.63.831zm9.778-7.137v1.412c0 1.992-1.377 3.717-4.153 5.177v.125c0 .863 1.726 3.717 5.202 8.549 1.144 1.584 2.03 2.369 2.707 2.369v.282h-1.26c-2.636 0-5.133-2.698-7.49-8.11-1.097-1.584-2.076-2.384-2.917-2.384h-1.867c-.21 0-.42.141-.63.423v9.789h-2.076V6.614h7.07c3.01 0 4.807 1.538 5.414 4.612z"
                mask="url(#mask-2)"
              ></path>
              <path
                fill={colors[fill]}
                d="M189.089 10.693v7.09c0 6.98 2.543 10.463 7.63 10.463 3.267 0 5.553-1.647 6.883-4.957.373-1.647.56-3.012.56-4.079V11.4l-.023-4.8h.747l-.117 4.094c-.023.58-.047 2.117-.047 4.612v3.545c0 3.89-1.05 6.73-3.15 8.517-1.656 1.177-3.5 1.773-5.576 1.773h-.56c-4.318 0-7.001-1.945-8.004-5.851a11.546 11.546 0 01-.373-3.012v-9.584l-.14-4.063h2.146l.024 4.063"
                mask="url(#mask-2)"
              ></path>
              <path
                fill={colors[fill]}
                d="M228.753 15.9c.28.832 1.54 3.687 3.78 8.597h.211c.513-.283 1.983-3.247 4.387-8.879 2.38-5.694 3.78-8.69 4.2-9.004h1.446c.28.032.421.126.421.283 0 1.521.07 6.447.21 14.776l.14 1.836.046.25.024.173v4.926c0 .188-.14.282-.42.282h-1.867V13.093c0-2.447-.21-3.67-.63-3.67h-.21c-1.82 3.874-4.48 9.976-7.958 18.305-.28.753-.56 1.13-.839 1.13l-7.537-18.871h-.211c-1.482.003-1.074 10.722-1.202 11.982-.233 2.281-.057 4.6-.057 6.889 0 .188-.14.282-.42.282h-.63v-2.683c.839-12.156 1.283-18.713 1.33-19.639l.35-.204h.84c.49 0 2.03 3.09 4.596 9.286"
                mask="url(#mask-2)"
              ></path>
              <path
                fill={colors[fill]}
                d="M206.867 1.606h-21.93c-.188 0-.282-.108-.282-.321V.32c0-.214.094-.321.282-.321h21.93c.188.036.282.143.282.321v.964c-.032.213-.126.32-.282.32"
                mask="url(#mask-2)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default RaindrumLogo;
