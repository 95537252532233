import React, { useContext, useEffect } from "react";
import { HeaderContext } from "../../../context/HeaderContext";
import { BsTelephone } from "react-icons/bs";
import { SiteSettingsContext } from "../../../context/SiteSettingsContext";
import S from "./styles";

const Phone = props => {
  const { theme } = useContext(HeaderContext);

  const {
    settings: { contactTypeformUrl, contactNumber, contactPageID },
  } = useContext(SiteSettingsContext);

  return (
    <S.Phone color={theme}>
      <a href={`tel:${contactNumber}`}>
        <BsTelephone className="icon" color="white" />
      </a>
      <a href={`tel:${contactNumber}`} className="number">
        {contactNumber}
      </a>
    </S.Phone>
  );
};

export default Phone;
