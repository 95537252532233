import styled from "styled-components";

const Footer = styled.footer`
  position: relative;
  padding: 120px 0 60px 0;
  background-color: ${({ theme }) => theme.colors.lightGrey};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 70px 0 40px 0;
  }

  .content {
    position: relative;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      flex-direction: column;
    }

    .left,
    .right {
      width: 33.3333%;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        width: 100%;
      }
    }

    .logo {
      display: inline-block;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        display: block;
        text-align: center;
      }
    }
  }
`;

export default {
  Footer,
};
