import React, { useRef, useEffect } from "react";
import * as typeformEmbed from "@typeform/embed";
import S from "./styles";

export interface FormProps {
  open: boolean;
  formUrl: string;
  setOpen: (boolean) => void;
}

const Form = ({ open, setOpen, formUrl }: FormProps) => {
  const typeformRef = useRef(null);

  useEffect(() => {
    typeformEmbed.makeWidget(typeformRef.current, formUrl, {
      hideFooter: true,
      hideHeaders: true,
    });
  }, [typeformRef]);

  return (
    <S.Form open={open} onClick={() => {
        setOpen(false);
      }}>
      <div className="form-wrapper">
        <div
          className="typeform"
          ref={typeformRef}
          style={{ height: "100vh" }}
        ></div>
      </div>
    </S.Form>
  );
};

export default Form;
