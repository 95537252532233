import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { TransitionPortal } from "gatsby-plugin-transition-link";
import Header from "../../components/layout/Header";
import ContactCTA from "../../components/layout/ContactCTA";
import InsightsBar from "../../components/content/InsightsBar";
import Footer from "../../components/layout/Footer";
import Credits from "../../components/layout/Credits";
import { theme } from "../../styles/theme";
import GlobalStyle from "../../styles/GlobalStyle";
import Locations from "../../components/content/Locations";

interface LayoutProps {
  children: React.ReactNode;
  location?: any;
  pathContext?: any;
  [prop: string]: any;
}

const Layout: React.FunctionComponent<LayoutProps> = ({
  children,
  location,
  pathContext,
}) => {
  useEffect(() => {
    document.body.classList.remove("menu-open");
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <TransitionPortal>
        <Header />
      </TransitionPortal>
      <main>{children}</main>
      <ContactCTA pathContext={pathContext} />
      <Locations />
      <InsightsBar />
      <Footer location={location} />
      <Credits />
    </ThemeProvider>
  );
};

export default Layout;
