import React from "react";

interface IconProps {
  fill: string;
  style?: React.CSSProperties;
}

const colors = {
  light: "white",
  dark: "black",
};

const EnvelopeIcon = ({ fill, style }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="27"
      viewBox="0 0 37 27"
      style={style}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g
          stroke={colors[fill]}
          strokeWidth="1.5"
          transform="translate(-1262 -44)"
        >
          <g transform="translate(69 40)">
            <g transform="translate(1194 5)">
              <g>
                <path d="M0 0H35V24.595H0z"></path>
                <path d="M0 4.70494514e-14L17.5 16.5870522 35 4.70494514e-14"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default EnvelopeIcon;
