import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import S from "./styles";
import { createMenuStructure } from "../../../utils/helpers";

const ContactMenu = () => {
  const { data } = useStaticQuery(graphql`
    query ContactMenuQuery {
      data: contentfulMenu(position: { eq: "contact" }) {
        items {
          id
          title
          slug
          externalLink
          items {
            id
            title
            slug
            externalLink
            items {
              id
              title
              slug
              externalLink
            }
          }
        }
      }
    }
  `);

  if (!data && !data.items) {
    return null;
  }

  return (
    <S.ContactMenu>
      {data.items && createMenuStructure(data.items)}
    </S.ContactMenu>
  );
};

export default ContactMenu;
