import React, { useContext } from "react";
import { SiteSettingsContext } from "../../../context/SiteSettingsContext";
import Container from "../../common/Container";
import S from "./styles";

interface LocationsContentProps {}

const Locations = ({}: LocationsContentProps) => {
  const {
    settings: { locations },
  } = useContext(SiteSettingsContext);
  return (
    <S.Locations>
      <Container>
        <div className="grid">
          {locations?.detail.map((location, index) => (
            <S.Location key={index}>
              {location.title && <h3>{location.title}</h3>}
              {location.addressLink && (
                <a href={location.addressLink}>
                  {location.address && <h4>{location.address}</h4>}
                  {location.addressLine2 && <h4>{location.addressLine2}</h4>}
                </a>
              )}
            </S.Location>
          ))}
        </div>
      </Container>
    </S.Locations>
  );
};

export default Locations;
