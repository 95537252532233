import React, { useContext } from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Container from "../../common/Container";
import RaindrumULogo from "../../common/Logos/RaindrumULogo";
import ArrowRight from "../../../assets/images/arrow_right_white.svg";
import S from "./styles";
import { SiteSettingsContext } from "../../../context/SiteSettingsContext";

const InsightsBar = () => {
  const { settings } = useContext(SiteSettingsContext);

  return (
    <S.InsightsBar>
      <Container>
        <div className="content">
          <AniLink
            to={settings.insightsPageSlug}
            bg="white"
            cover
            direction="right"
          >
            <RaindrumULogo fill="white" />
          </AniLink>
          <AniLink
            to={settings.insightsPageSlug}
            bg="white"
            cover
            direction="right"
          >
            <h4>Insights</h4>
          </AniLink>
          <AniLink
            to={settings.insightsPageSlug}
            bg="white"
            cover
            direction="right"
          >
            <p>View Articles</p>
          </AniLink>
          <ArrowRight />
        </div>
      </Container>
    </S.InsightsBar>
  );
};

export default InsightsBar;
