import { DefaultTheme, ThemedStyledComponentsModule } from "styled-components";

interface Theme {
  colors: {
    [color: string]: string;
  };
  fonts: {
    [fontFamily: string]: string;
  };
  breakpoints: {
    [breakpoint: string]: string;
  };
}

const colors = {
  white: "#FFFFFF",
  lightGreen: "#C1D5CC",
  lightGrey: "#F6F6F6",
  lightGrey2: "#D8E1DD",
  mediumGrey: "#909090",
  mediumGrey2: "#6B8083",
  darkGrey: "#3D4A4B",
  darkGrey2: "#383838",
  darkGrey3: "#454545",
};

const fonts = {
  butlerMedium: "Butler-Medium",
  ttNormsMedium: "TTNorms-Medium",
  ttNormsBold: "TTNorms-Bold",
  ttNormsRegular: "TTNorms-Regular",
};

const breakpoints = {
  small: "650px",
  medium: "1200px",
  large: "1800px",
};

const mixins = {
  imageScale: `
    transition: transform 2s ease;
    transform: scale(1);

    &:hover {
      transform: scale(1.1);
    }
  `,
  underlineLink: `
    &:before,
    &:after {
      content: "";
      position: absolute;
      bottom: -5px;
      width: 0px;
      height: 3px;
      margin: 5px 0 0;
      transition: all 0.4s ease-in-out;
      opacity: 0;
      width: 0;
      background-color: ${colors.darkGrey};
      left: 0;
    }

    &:hover {
      cursor: pointer;
      &:before,
      &:after {
        width: 100%;
        opacity: 1;
      }
    }
  `,
};

export const theme = {
  colors,
  fonts,
  breakpoints,
  mixins,
};
