import styled from "styled-components";

const ContactMenu = styled.nav`
  position: relative;
  margin-top: 50px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-family: ${({ theme }) => theme.fonts.ttNormsRegular};
    font-size: 15px;
    letter-spacing: 0.2px;
    line-height: 25px;

    li {
      width: 50%;
    }

    a {
      color: ${({ theme }) => theme.colors.darkGrey};
      text-decoration: none;
    }
  }
`;

export default {
  ContactMenu,
};
