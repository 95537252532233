import React, { useState, useContext } from "react";
import { SiteSettingsContext } from "../../../context/SiteSettingsContext";
import EnvelopeIcon from "../../common/Icons/EnvelopeIcon";
import ArrowRightIcon from "../../../assets/images/arrow_right.svg";
import S from "./styles";

const NewsletterSignup = ({ location }) => {
  const {
    settings: {
      thankYouPageSlug,
      salesforceSubscribeFormOid,
      salesforceSubscribeFormEndpoint,
    },
  } = useContext(SiteSettingsContext);
  const [email, setEmail] = useState("");
  const [focused, setFocused] = useState(false);

  return (
    <S.NewsletterSignup>
      <form
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        action={salesforceSubscribeFormEndpoint}
        method="POST"
      >
        <input type="hidden" name="oid" value={salesforceSubscribeFormOid} />
        <input type="hidden" id="00N2u000000KUJf" name="00N2u000000KUJf" value="1" />
        <input type="hidden" id="lead_source" name="lead_source" value="Email Newsletter"/>
        <input
          type="hidden"
          name="retURL"
          value={`${location ? location.origin : ""}${thankYouPageSlug}`}
        />
        <EnvelopeIcon
          style={{
            transform: focused ? "translateX(-59px)" : "translateX(0px)",
            opacity: focused ? 0 : 1,
            position: "absolute",
            transition: "all 0.2s ease",
          }}
          fill="dark"
        />
        <input
          type="email"
          placeholder="Subscribe to our newsletter"
          onChange={e => setEmail(e.target.value)}
          name="email"
          id="email"
          value={email}
          style={{
            paddingLeft: focused ? 0 : "59px",
            transition: "all 0.2s ease",
          }}
        />
        <button className="submit">
          <ArrowRightIcon
            style={{
              position: "absolute",
              transition: "all 0.1s ease",
            }}
          />
        </button>
      </form>
    </S.NewsletterSignup>
  );
};

export default NewsletterSignup;
