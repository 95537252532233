import styled from "styled-components";
import AniLink from "gatsby-plugin-transition-link/AniLink";

const Locations = styled.section`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};

  .grid {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    max-width: 1080px;
    margin: 0 auto;
    padding-top: 40px;
    text-align: center;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    }
  }
`;

const Location = styled.div`
  position: relative;
  padding: 0 20px 100px 20px;
  width: 50%;
  text-decoration: none;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 50%;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    width: 100%;
    padding: 0;
    margin-bottom: 60px;
  }
  }

  h3 {
    font-family: ${({ theme }) => theme.fonts.butlerMedium};
    font-size: 35px;
    line-height: 40px;
    font-weight: normal;
    margin-bottom: 0;
  }

  h4 {
    font-family: ${({ theme }) => theme.fonts.ttNormsRegular};
    font-size: 22px;
    line-height: 36px;
    font-weight: normal;
    margin: 0;
  }
`;

export default {
  Locations,
  Location,
};
