import styled from "styled-components";

interface HeaderProps {
  scrolled: boolean;
  headerVisible: boolean;
}

const Header = styled.header<HeaderProps>`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 30px 60px;
  z-index: 5;
  transition: all 0.5s cubic-bezier(0.25, 1, 0.5, 1);
  background-color: ${({ scrolled }) => (scrolled ? "white" : "transparent")};
  transform: ${({ headerVisible }) =>
    headerVisible ? `translateY(0)` : `translateY(-100%)`};
  opacity: ${({ headerVisible }) => (headerVisible ? 1 : 0)};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 20px;

    .logo {
      svg {
        max-width: 200px;
      }
    }
  }
`;

export default {
  Header,
};
